/* Fonts */
@font-face {
  font-family: YekanBakh;
  src: url("./Assets/Fonts/Yekan-Bakh-EN-05-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: YekanBakh;
  src: url("./Assets/Fonts/Yekan-Bakh-EN-03-Light.woff");
  font-weight: 300;
}

@font-face {
  font-family: YekanBakh;
  src: url("./Assets/Fonts/Yekan-Bakh-EN-01-Hairline.woff");
  font-weight: 100;
}


body {
  margin: 0;
  font-family: YekanBakh,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

input {
  font-family: YekanBakh !important;

}

Input::placeholder {
  font-family: YekanBakh !important;
  font-size: 12px;
}


html {
  direction: rtl;
  font-family: YekanBakh !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}